<template>
  <iframe :src="`${apiUrl}/site_settings/legal_documents?document_name=${document}`" />
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      document: "",
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    ...mapGetters({
      companySettings: "companySettings",
      isCompanyFetched: "isCompanyFetched",
    }),
  },
  created() {
    this.document = this.$route.params.document;
  },
};
</script>
<style>
iframe {
  width: 100%;
  min-height: 100vh;
}
</style>
