var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('iframe', {
    attrs: {
      "src": ((_vm.apiUrl) + "/site_settings/legal_documents?document_name=" + (_vm.document))
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }